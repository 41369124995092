.thumbnail-container img{
    border-radius: 15px;
}
/* Default styles for screens larger than or equal to 768px */
.custom-thumbnail {
    width: 800px;
    height: 450px;
  }
  
  .video-player {
    width: 800px;
    height: 450px;
  }
  
  /* Media query for screens below 768px */
  @media screen and (max-width: 768px) {

    .thumbnail-container{
      /* margin: 10%; */
      width: 100%;
      margin: auto;
    }
    .custom-thumbnail {
      width: 100%; /* Set to a percentage value for responsiveness */
      max-width: 330px; /* Maximum width for screens below 768px */
      height: auto;
      margin: auto;
      
    }
  
    .video-player {
      width: 100%; /* Set to a percentage value for responsiveness */
      max-width: 330px; /* Maximum width for screens below 768px */
      height: auto; /* Maintain aspect ratio */
      /* border: 1px solid red; */
      margin: auto;
      /* margin: 20px; */
      overflow: hidden;
    }
  }
  