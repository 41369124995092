.search-container {
    position: relative;
    height: fit-content;
    width: 78%;
    margin: auto;
    background: linear-gradient(135deg, #f9f9f9 0%, #ffefe3 100%);
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 65px;

    /* border: 1px solid; */
  }
  
  .Search{
    padding-top: 65px;
  }
  .search-bar {
    display: flex;
    align-items: center;
    background: white;
    border-radius: 30px;
    padding: 10px 20px;
    width: 90%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
.selected-tags-container::-webkit-scrollbar {
  display: none;
}

.selected-tags-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

  .search-bar input {
    border: none;
    outline: none;
    font-size: 16px;
    flex: 1;
    padding: 10px 0px;
    border-radius: 30px;
  }
  
  .search-bar button {
    background-color: #FF410B;
    color: white;
    border: none;
    border-radius: 30px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
 

@media (max-width: 768px) {
 
    .search-container{
        width: 95%;
        padding: 10px;
    }
    .search-bar{
        width: 100%;
        padding: 5px 10px;
    }

    .search-bar button{
        padding: 5px 10px;
    }


}