/* src/components/Timeline.css */

.custom-vertical-timeline {
    position: relative;
    
  }
  .vertical-timeline-element-content {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  
  
  .custom-vertical-timeline:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    width: 2px; /* Adjust line width as needed */
    background-color: #ccc; /* Line color */
  }

  .custom-div{
    padding: 0;
    margin:0;
  }

  @media (max-width: 768px) {
    .custom-vertical-timeline:before {
        content: '';
        position: absolute;
        top: 0;
        left: 25px;
        height: 100%;
        width: 2px; /* Adjust line width as needed */
        background-color: #ccc; /* Line color */
      }

      .custom-vertical-timeline {
        padding: 0px;
        margin: 0px;

      }

      .vertical-timeline-element-content {
        height: fit-content;
        padding:10px 5px;
        margin: 0 0 0 30px;

      }
      .custom-vertical-timeline-element{
        margin-bottom: 50px;
        margin-left: 8px;
      }

      .timeline-image{
        height: 280px;
        width: 300px;
        object-fit: cover;
      }
  }
  
  
  .custom-icon {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
  
  .vertical-timeline-element-content {
    position: relative;
    z-index: 2;
  }
  
  .timeline-image {
    max-width: 100%; /* Ensure the image does not exceed its container */
    margin-top: 10px; /* Adjust spacing as needed */
  }
  



  