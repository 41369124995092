*{
    margin: 0;
    padding: 0;
}
.Product{
    padding-top: 20px;
}

.message-box{
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);

}
.Product-Card {
    display: flex;
    flex-wrap: wrap; /* Allows wrapping to the next row */
    justify-content: space-between; /* Distributes space between columns */
    margin: auto;
    width: 77%;
    gap: 10px;
    padding-top: 10px;
}

.Product-Card .Category-Card { /* Assuming each product card has a class of "card" */
    flex: 1 1 calc(33.33% - 20px); /* 3 columns; minus gap */
    box-sizing: border-box;
    margin-bottom: 20px; /* Space between rows */
}

/* .Product-Card :hover{
    box-shadow: 2px 4px 4px 2px rgba(249, 70, 11, 0.2), 4px 6px 10px 4px rgba(0, 0, 0, 0.19);

} */

.Category-Card1{
    display: flex;
    border: 1px solid orangered;
    height: 120px;
    width: 350px;
    background-color:#FF5757 ;
    border-radius: 6px;
    box-shadow: 0 1px 2px 0 rgba(249, 70, 11, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
}


.CatH{
    font-family: Quicksand;
    text-align: center;
    margin-top: 12%;
    margin-left: 10%;
    /* border: 1px solid; */
}

.CatH h3{
    color: white;
    font-size: 20px;
}


.Category-Card2{
    display: flex;
    border: 1px solid orangered;
    height: 120px;
    width: 400px;
    background-color: #8C52FF;
    border-radius: 6px;
    box-shadow: 0 1px 2px 0 rgba(249, 70, 11, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);

}

.Category-Card3{
    display: flex;
    border: 1px solid orangered;
    height: 120px;
    width: 400px;
    background-color: #FF671C;
    border-radius: 6px;
    box-shadow: 0 1px 2px 0 rgba(249, 70, 11, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);

}
.Category-Card4{
    display: flex;
    border: 1px solid orangered;
    height: 120px;
    width: 400px;
    background-color: #7ED957;
    border-radius: 6px;
    box-shadow: 0 1px 2px 0 rgba(249, 70, 11, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);

}

.Category-Card5{
    display: flex;
    border: 1px solid orangered;
    height: 120px;
    width: 400px;
    background-color: #EDCC1F;
    border-radius: 6px;
    box-shadow: 0 1px 2px 0 rgba(249, 70, 11, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);

}
.Category-Card6{
    display: flex;
    border: 1px solid orangered;
    height: 120px;
    width: 400px;
    background-color: #CB6CE6;
    border-radius: 6px;
    box-shadow: 0 1px 2px 0 rgba(249, 70, 11, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);

}

.Cat-Image{
    width: 95%;
    border-radius: 6px;
    margin: 1px;
    /* border: 2px solid; */
}
.CatI{
    width: 30%;
    border-radius: 6px;
    margin: 1px;
    /* border: 2px solid; */
}


/* Recommended */
.Recommended{
    width: 80%;
    margin: auto;
    /* border: 2px solid orangered; */
    border-radius: 6px;
    margin-top: 20px;
    gap: 10px;
}

.Rec-Heading{
    width: 77%;
    margin: auto;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    /* margin-top: 15px; */
}

.Rec-Heading h3{
    text-align: center;
}

.Rec-Card{
    /* border: 2px solid orangered; */
    border-radius: 6px;
    /* width: 25%; */
    box-shadow: 0 1px 2px 0 rgba(249, 70, 11, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);

}

.Rec-Card:hover{
    border: 2px solid orangered;
    
}

.ProdI{
    width: 100%;
    height: 190px;
    /* border: 2px solid orangered; */
    
}

.Prod-Img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    
}

hr{
    margin-top: 20px;
    width: 90%;
    margin-left: 5%;
    
}


.Prod-Name{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin-left: 5%;
    margin-top: 3%;
}

.Prod-Price{
    width: 80px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    border-radius: 6px;
    margin-left: 5%;
    /* margin-bottom: 2px; */
    font-weight: 500;

    /* border: 1px solid orangered; */
}
.Prod-Price h3{
    color: orangered;
}

.Prod-Description{
    margin: 5%;
}

@media (max-width: 768px) {

    .Recommended{
        width: 95%;
    }
    .ProdI{
        width: 100%;
        height: 120px;
        /* border: 2px solid orangered; */
        
    }

    .Prod-I{
        height: fit-content;
    }

    .Prod-Description{
        display: none;
    }
    
    .Prod-Name{
        margin: 0;
    }

    .Prod-Price{
        margin: 0;
    }
}