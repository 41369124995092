.shape {
    position: absolute;
    background: #ffcc00;
    border-radius: 50%;
    opacity: 0.6;
}

.shape1 {
    width: 50px;
    height: 50px;
    top: -20px;
    left: -20px;
}

.shape2 {
    width: 30px;
    height: 30px;
    top: -10px;
    right: -10px;
}

.shape3 {
    width: 40px;
    height: 40px;
    bottom: -20px;
    left: 30%;
}

.shape4 {
    width: 60px;
    height: 60px;
    bottom: -30px;
    right: 20%;
}
