@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body{
  font-family: "Montserrat", sans-serif;
  background-color: rgb(255, 255, 255);
}

.text-stroke-3 {
  -webkit-text-stroke: 3px black;
}

.color{
  color: #FF410B;
}

.bg-theme{
  background-color: #FF410B;
}
.font{
  font-size: 650px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

@keyframes slideIn {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
}

.slide-in {
  animation: slideIn 0.3s forwards;
}

.slide-out {
  animation: slideOut 0.3s forwards;
}

.text-truncate {
  overflow: hidden;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
}


/* src/components/Search.css */

/* Shimmer animation */
@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.animate-shimmer {
  /* background: linear-gradient(
    to right,
    #f97316 20%,
    #fb923c 40%,
    #f97316 60%
  ); */
  background-size: 200% auto;
  animation: shimmer 1.5s linear infinite;
}